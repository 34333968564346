// =============================
// Imports
// =============================

// External dependencies
import axios, { isCancel } from 'axios';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _mapKeys from 'lodash/mapKeys';
import _mapValues from 'lodash/mapValues';
import _uniq from 'lodash/uniq';

// Config
import { i18n } from '../../config/i18n';
import * as miscConfig from '../../config/misc';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import determineError from '../../helpers/errors';
import { camelCaseKeysDeep, getApiUrl, getXPreferredLanguage } from '../../helpers/misc';
import { cancelableRequest, cancelRequest } from '../helpers/axios';
import getTracksSearchDataBase from './SearchActions/getTracksSearchData';

// =============================
// Tracks Search Actions
// =============================

export const getTracksSearchData = getTracksSearchDataBase;

export function setSearchInDescription(value) {
  return {
    type: acts.SEARCH_SET_DESCRIPTION_INCLUSION,
    payload: value,
  };
}

export function getInitialFacets() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_INITIAL_FACETS_LOADING,
    });

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl('public/search/facets'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: {},
      });

      const camelizedData = camelCaseKeysDeep(response.data);

      dispatch({
        type: acts.SET_INITIAL_FACETS,
        payload: {
          facets: {
            ...camelizedData,
            versions: _mapKeys(camelizedData.versions, (_v, k) => k.toLowerCase()),
            tags: _mapKeys(camelizedData.tags, (_v, k) => k.toLowerCase()),
          },
        },
      });

      dispatch({
        type: acts.GET_INITIAL_FACETS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_INITIAL_FACETS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

const POLL_TIMEOUT = 2000;

export function continueMaiaSearch(data) {
  return async (dispatch, getState) => {
    if (!getState().search.tracksSearch.isDoingMaiaSearch) return undefined;
    await dispatch(getTracksSearchData(data, { reload: true }));
    if (!getState().search.tracksSearch.isDoingMaiaSearch) return undefined;
    await new Promise(resolve => setTimeout(resolve, POLL_TIMEOUT));

    return dispatch(continueMaiaSearch(data));
  };
}

export function requestMaiaTextSearch(value, displayValue) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REQUEST_MAIA_TEXT_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.REQUEST_MAIA_TEXT, {
        method: 'post',
        url: getApiUrl('public/search/url'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: { link: value },
      });

      dispatch({
        type: acts.SET_MAIA_REQUEST,
      });

      dispatch({
        type: acts.REQUEST_MAIA_TEXT_SUCCESS,
        payload: {
          // NOTE: Data is a job id
          value: response.data,
          displayValue,
        },
      });
    } catch (err) {
      if (!isCancel(err)) {
        let errorMsg;

        switch (true) {
          case err.response && err.response.data.key === 'inactive_subscription':
            errorMsg = i18n.t('errors:search.maia_inactive_subscription');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        dispatch({
          type: acts.REQUEST_MAIA_TEXT_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function requestMaiaAudioSearch(file) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REQUEST_MAIA_AUDIO_LOADING,
    });

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await cancelableRequest(rqs.REQUEST_MAIA_AUDIO, {
        method: 'post',
        url: getApiUrl('public/search/upload'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        onUploadProgress: (event) => {
          const progress = (event.loaded / event.total) * 100;

          dispatch({
            type: acts.SET_MAIA_AUDIO_UPLOAD_PROGRESS,
            payload: parseInt(progress, 10),
          });
        },
        data: formData,
      });

      dispatch({
        type: acts.SET_MAIA_REQUEST,
      });

      dispatch({
        type: acts.REQUEST_MAIA_AUDIO_SUCCESS,
        payload: {
          // NOTE: Data is a job id
          value: response.data,
          displayValue: file.name,
        },
      });
    } catch (err) {
      if (!isCancel(err)) {
        let errorMsg;

        switch (true) {
          case err.response && err.response.data.key === 'inactive_subscription':
            errorMsg = i18n.t('errors:search.maia_inactive_subscription');
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        dispatch({
          type: acts.REQUEST_MAIA_AUDIO_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function updateTracksSearchFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_TRACKS_SEARCH_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateTracksSearchRecents(id) {
  return {
    type: acts.UPDATE_TRACKS_SEARCH_RECENTS,
    payload: { id },
  };
}

export function resetTracksSearchData() {
  cancelRequest(rqs.GET_TRACKS_SEARCH_DATA);

  return {
    type: acts.RESET_TRACKS_SEARCH_DATA,
  };
}

// =============================
// Pre Search Actions
// =============================

export function setSearchValue(data) {
  return {
    type: acts.SET_SEARCH_VALUE,
    payload: data,
  };
}

export function getPreSearchData(value) {
  return async (dispatch, getState) => {
    if (!value) return;

    const { tags_sc: tagsSc } = getState().options.data;
    const { searchInDescription } = getState().search;
    const { initialFacets } = getState().search.tracksSearch.data;

    const tagsScMapping = _mapValues(
      _keyBy(tagsSc, 'id'),
      tagSc => tagSc.tags.map(tag => tag.id),
    );

    dispatch({
      type: acts.GET_PRE_SEARCH_DATA_LOADING,
    });

    const baseOptions = {
      max: miscConfig.SEARCH_PANEL_ITEMS,
      search_descriptions: searchInDescription,
    };

    try {
      const [
        entitiesResponse,
        tracksResponse,
        tagsResponse,
        tagCategoriesResponse,
      ] = await Promise.all([
        cancelableRequest(rqs.GET_PRE_SEARCH_ENTITIES, {
          method: 'post',
          url: getApiUrl('public/search/panel'),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
          data: {
            query: value,
            albums: { page: 0, ...baseOptions },
            catalogs: { page: 0, ...baseOptions },
            playlists: { page: 0, ...baseOptions },
            artists: { page: 0, ...baseOptions },
            labels: { page: 0, ...baseOptions },
            publishers: { page: 0, ...baseOptions },
          },
        }),
        cancelableRequest(rqs.GET_PRE_SEARCH_TRACKS, {
          method: 'post',
          url: getApiUrl('public/search/panel/track'),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
          data: {
            query: value,
            options: {
              size: miscConfig.SEARCH_PANEL_ITEMS,
              search_descriptions: searchInDescription,
            },
          },
        }),
        cancelableRequest(rqs.GET_PRE_SEARCH_TAGS, {
          method: 'post',
          url: getApiUrl('public/search/tags'),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
          data: {
            query: value,
            tags: Object.keys(_get(initialFacets, 'tags', {})),
            max: 10,
          },
        }),
        cancelableRequest(rqs.GET_PRE_SEARCH_TAG_CATEGORIES, {
          method: 'post',
          url: getApiUrl('public/search/tag_categories'),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
          data: {
            query: value,
            // Value can be null if it's a tag category and not a tag sub category
            tagCategories: _compact(
              _uniq(
                Object.keys(_get(initialFacets, 'tags', {})).map(tagId => Object.keys(tagsScMapping)
                  .find(tagScId => tagsScMapping[tagScId].find(id => id === tagId)),
                ),
              ),
            ),
            max: 6,
          },
        }),
      ]);

      const camelizedEntityData = camelCaseKeysDeep(entitiesResponse.data);
      const camelizedTrackData = camelCaseKeysDeep(tracksResponse.data);
      const camelizedTagData = tagsResponse.data;
      const camelizedTagCategoryData = camelCaseKeysDeep(tagCategoriesResponse.data);

      dispatch({
        type: acts.SET_PRE_SEARCH_DATA,
        payload: {
          albums: {
            data: camelizedEntityData.albums.hits,
            total: camelizedEntityData.albums.total,
          },
          catalogs: {
            data: camelizedEntityData.catalogs.hits,
            total: camelizedEntityData.catalogs.total,
          },
          artists: {
            data: camelizedEntityData.artists.hits,
            total: camelizedEntityData.artists.total,
          },
          playlists: {
            data: camelizedEntityData.playlists.hits,
            total: camelizedEntityData.playlists.total,
          },
          labels: {
            data: camelizedEntityData.labels.hits,
            total: camelizedEntityData.labels.total,
          },
          publishers: {
            data: camelizedEntityData.publishers.hits,
            total: camelizedEntityData.publishers.total,
          },
          tracks: {
            data: camelizedTrackData.hits,
            total: camelizedTrackData.total,
          },
          tags: {
            data: camelizedTagData.hits,
            total: camelizedTagData.total,
          },
          tagCategories: {
            data: camelizedTagCategoryData.hits,
            total: camelizedTagCategoryData.total,
          },
        },
      });

      dispatch({
        type: acts.GET_PRE_SEARCH_DATA_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let errorMsg;

        switch (true) {
          case err.response
            && err.response.status === 400
            && err.response.data.message.includes('must be less than or equal to 500 characters long'):
            errorMsg = i18n.t('errors:search.query_too_long', { max: 500 });
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        dispatch({
          type: acts.GET_PRE_SEARCH_DATA_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function resetPreSearchData() {
  cancelRequest(rqs.GET_PRE_SEARCH_ENTITIES);
  cancelRequest(rqs.GET_PRE_SEARCH_TRACKS);
  cancelRequest(rqs.GET_PRE_SEARCH_TAGS);
  cancelRequest(rqs.GET_PRE_SEARCH_TAG_CATEGORIES);
  cancelRequest(rqs.REQUEST_MAIA_AUDIO);
  cancelRequest(rqs.REQUEST_MAIA_TEXT);

  return {
    type: acts.RESET_PRE_SEARCH_DATA,
  };
}

// =============================
// FullSizeCover Search Actions
// =============================

export function setFullSizeCoverModuleData(data) {
  return {
    type: acts.SET_FULL_SIZE_COVER_MODULE_DATA,
    payload: data,
  };
}

export function clearFullSizeCoverModuleData(id) {
  return {
    type: acts.CLEAR_FULL_SIZE_COVER_MODULE_DATA,
    payload: id,
  };
}

// =============================
// Entity Search Actions
// =============================

export function getEntitySearchData(type, page, query) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ENTITY_SEARCH_DATA_LOADING,
    });

    const { searchInDescription } = getState().search;

    try {
      const response = await cancelableRequest(rqs.GET_ENTITY_SEARCH_DATA, {
        method: 'post',
        url: getApiUrl('public/search/panel'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: {
          query,
          [type]: {
            page,
            max: miscConfig.COVER_ITEMS_PER_PAGE,
            search_descriptions: searchInDescription,
            accurate: true,
          },
        },
      });

      const responseData = camelCaseKeysDeep(response.data)[type];

      dispatch({
        type: acts.SET_ENTITY_SEARCH_DATA,
        payload: {
          total: responseData.total,
          nbPages: responseData.pages,
          page,
          data: responseData.hits,
          type,
        },
      });

      dispatch({
        type: acts.GET_ENTITY_SEARCH_DATA_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let errorMsg;

        switch (true) {
          case err.response
          && err.response.status === 400
          && err.response.data.message.includes('must be less than or equal to 500 characters long'):
            errorMsg = i18n.t('errors:search.query_too_long', { max: 500 });
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        dispatch({
          type: acts.GET_ENTITY_SEARCH_DATA_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function resetEntitySearchData() {
  cancelRequest(rqs.GET_ENTITY_SEARCH_DATA);

  return {
    type: acts.RESET_ENTITY_SEARCH_DATA,
  };
}

// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import Cookies from 'js-cookie';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import downloadLink from '../../helpers/download';
import determineError from '../../helpers/errors';
import {
  camelCaseKeysDeep,
  getApiUrl,
  getCookieConfig,
  getXPreferredLanguage,
} from '../../helpers/misc';

// =============================
// Actions
// =============================

export function toggleDesc(payload) {
  return (dispatch, getState) => {
    const { serverContext } = getState().core;

    if (typeof window !== 'undefined') {
      Cookies.set(
        'showTrackDesc',
        payload,
        // 1 year
        getCookieConfig(365, serverContext.locationProtocol),
      );
    }

    return dispatch({
      type: acts.SHOW_TRACK_DESC,
      payload,
    });
  };
}

export function toggleTags(payload) {
  return (dispatch, getState) => {
    const { serverContext } = getState().core;

    if (typeof window !== 'undefined') {
      Cookies.set(
        'showTrackTags',
        payload,
        // 1 year
        getCookieConfig(365, serverContext.locationProtocol),
      );
    }

    return dispatch({
      type: acts.SHOW_TRACK_TAGS,
      payload,
    });
  };
}

export function getVersions(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_VERSIONS_LOADING,
      payload: { id },
    });

    try {
      const response = await axios.get(getApiUrl(`public/tracks/${id}/versions`), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_VERSIONS,
        payload: {
          id,
          data: camelCaseKeysDeep(response.data),
        },
      });

      dispatch({
        type: acts.GET_VERSIONS_SUCCESS,
        payload: { id },
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_track_not_found':
          message = i18n.t('errors:pitch.track_not_found');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_not_found':
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_token_expired':
          message = i18n.t('errors:pitch.invalid_token');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.GET_VERSIONS_FAILURE,
        payload: {
          id,
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function updateVersionsFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_VERSION_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateVersionsRecents(id) {
  return {
    type: acts.UPDATE_VERSION_RECENTS,
    payload: { id },
  };
}

export function downloadAudiofile(id, quality) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.DOWNLOAD_AUDIOFILE_LOADING,
    });

    try {
      const response = await fetch(getApiUrl(`public/tracks/${id}/download?format=${quality}`), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      if (!response.ok) {
        const error = new Error(response.statusText);
        // Reconstruct error stream style
        error.response = {
          status: response.status,
          data: { key: response.statusText },
        };
        throw error;
      }

      downloadLink(await response.json());

      dispatch({
        type: acts.DOWNLOAD_AUDIOFILE_SUCCESS,
      });
    } catch (err) {
      let message;
      let errorType = 'error';

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_track_not_found':
          message = i18n.t('errors:pitch.track_not_found');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_not_found':
          message = i18n.t('errors:pitch.invalid_token');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_token_expired':
          message = i18n.t('errors:pitch.invalid_token');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'not_found':
          message = i18n.t('errors:download.audiofile_not_found');
          break;

        case err.response
          && err.response.status === 406
          && err.response.data.key === 'not_acceptable':
          message = i18n.t('errors:download.no_permission');
          errorType = 'warning';
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'aws_s3_get_audiofile_not_found':
          message = i18n.t('errors:download.audiofile_s3_not_found');
          break;

        case err.response
          && err.response.status === 500
          && err.response.data.key === 'aws_s3_get_audiofile_error':
          message = i18n.t('errors:download.audiofile_s3_error');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'aws_s3_get_image_not_found':
          message = i18n.t('errors:download.audiofile_image_s3_not_found');
          break;

        case err.response
          && err.response.status === 500
          && err.response.data.key === 'aws_s3_get_image_error':
          message = i18n.t('errors:download.audiofile_image_s3_error');
          break;

        case err.response
          && err.response.status === 500
          && err.response.data.key === 'node_id3_error':
          message = i18n.t('errors:download.audiofile_node_id3_error');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.DOWNLOAD_AUDIOFILE_FAILURE,
        payload: {
          message,
          type: errorType,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function getFiles(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_FILES_LOADING,
      payload: { id },
    });

    try {
      const response = await axios.get(getApiUrl(`public/tracks/${id}/files`), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_FILES,
        payload: {
          id,
          data: camelCaseKeysDeep(response.data),
        },
      });

      dispatch({
        type: acts.GET_FILES_SUCCESS,
        payload: { id },
      });
    } catch (err) {
      let message;
      let errorType = 'error';

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_track_not_found':
          message = i18n.t('errors:pitch.track_not_found');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_not_found':
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_token_expired':
          message = i18n.t('errors:pitch.invalid_token');
          break;

        case err.response && err.response.status === 406:
          message = i18n.t('errors:download.no_permission');
          errorType = 'warning';
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.GET_FILES_FAILURE,
        payload: {
          id,
          message,
          type: errorType,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function notifyFileDownload(id, fileType) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.NOTIFY_FILE_DOWNLOAD_LOADING,
    });

    try {
      await axios.post(
        getApiUrl(`public/tracks/${id}/notifydownload`),
        { file_type: fileType },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            'x-pitch-token': getState().user.pitchToken,
            ...getState().core.serverContext.ssrRequestHeaders,
          },
        },
      );

      dispatch({
        type: acts.NOTIFY_FILE_DOWNLOAD_SUCCESS,
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_track_not_found':
          message = i18n.t('errors:pitch.track_not_found');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'not_found':
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'token_expired':
          message = i18n.t('errors:pitch.invalid_token');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.NOTIFY_FILE_DOWNLOAD_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

// =============================
// Imports
// =============================

// External Dependencies
import { isCancel } from 'axios';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';
import * as playerCtx from '../constants/PlayerContexts';
import * as rqs from '../constants/RequestTypes';

// Helpers
import determineError from '../../helpers/errors';
import { camelCaseKeysDeep, getApiUrl, getXPreferredLanguage } from '../../helpers/misc';
import { cancelableRequest } from '../helpers/axios';

// =============================
// Player action
// =============================

export function open() {
  return {
    type: acts.OPEN_PLAYER,
  };
}

export function close() {
  return {
    type: acts.CLOSE_PLAYER,
  };
}

export function setPlayerState(state) {
  return {
    type: acts.SET_PLAYER_STATE,
    payload: state,
  };
}

export function setGetCurrentTime(time) {
  return {
    type: acts.SET_PLAYER_GET_CURRENT_TIME_FNC,
    payload: time,
  };
}

export function fulfillRequest(req) {
  return {
    type: acts.FULFILL_PLAYER_REQUEST,
    payload: req,
  };
}

export function play() {
  return {
    type: acts.PLAYER_REQUEST_PLAY,
  };
}

export function pause() {
  return {
    type: acts.PLAYER_REQUEST_PAUSE,
  };
}

export function goToPrevTrack() {
  return {
    type: acts.PLAYER_REQUEST_GO_TO_PREV_TRACK,
  };
}

export function goToNextTrack() {
  return {
    type: acts.PLAYER_REQUEST_GO_TO_NEXT_TRACK,
  };
}

export function seek(st) {
  return {
    type: acts.PLAYER_REQUEST_SET_SEEKING_TIME,
    payload: st,
  };
}

export function reinitializePlayer() {
  return {
    type: acts.REINITIALIZE_PLAYER,
  };
}

// =============================
// API Actions
// =============================

export function getSingleMedia(trackId, contextName) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_MEDIA_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_PLAYER_MEDIA, {
        method: 'get',
        url: getApiUrl(`public/play/track/${trackId}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_SINGLE_MEDIA,
        payload: {
          current: camelCaseKeysDeep(response.data),
          trackId,
          contextName,
        },
      });

      dispatch({
        type: acts.GET_MEDIA_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_track_not_found':
            message = i18n.t('errors:pitch.track_not_found');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_not_found':
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_token_expired':
            message = i18n.t('errors:pitch.invalid_token');
            break;

          case err.response && err.response.status === 429:
            message = i18n.t('errors:player.too_many_plays');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('errors:player.track_not_found');
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_MEDIA_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function getMediaPlaylist(contextName, contextId = null, position = 1) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_MEDIA_LOADING,
    });

    try {
      const data = { context: { type: contextName, position } };
      if (contextId) {
        data.context.id = contextId;
      }

      const response = await cancelableRequest(rqs.GET_PLAYER_PLAYLIST, {
        method: 'post',
        url: getApiUrl('public/play/track'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data,
      });

      const camelizedResponse = camelCaseKeysDeep(response.data);

      dispatch({
        type: acts.SET_MEDIA_PLAYLIST,
        payload: {
          prev: camelizedResponse.prev,
          current: camelizedResponse.current,
          next: camelizedResponse.next,
          contextName,
          contextId,
          contextPosition: position,
        },
      });

      dispatch({
        type: acts.GET_MEDIA_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        switch (true) {
          case err.response
            && err.response.status === 406
            && err.response.data.key === 'pitch_play_context':
            message = i18n.t('errors:pitch.invalid_play_context');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_not_found':
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_token_expired':
            message = i18n.t('errors:pitch.invalid_token');
            break;

          case err.response && err.response.status === 429:
            message = i18n.t('errors:player.too_many_plays');
            break;

          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('errors:player.track_not_found');
            break;

          case err.response && err.response.status === 403:
            message = i18n.t('errors:player.no_access_context');
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_MEDIA_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function getMedia(trackId = null, contextName, contextId = null, position = 1) {
  return async (dispatch, getState) => {
    switch (contextName) {
      case playerCtx.SEARCH:
      case playerCtx.MUSIC_ITEM_VERSION: {
        const playerStore = getState().player;

        const currentTrackId = _get(playerStore, 'trackId', null);
        const currentContextName = _get(playerStore, 'contextName', null);

        if (currentTrackId === trackId && currentContextName === contextName) {
          dispatch(play());
        } else {
          dispatch(getSingleMedia(trackId, contextName));
        }

        break;
      }

      default: {
        const playerStore = getState().player;

        const currentContextName = _get(playerStore, 'contextName', null);
        const currentContextId = _get(playerStore, 'contextId', null);
        const currentContextPosition = _get(playerStore, 'contextPosition', null);

        if (
          (!trackId || trackId === _get(playerStore, 'current.id', null))
          && currentContextName === contextName
          && currentContextId === contextId
          && currentContextPosition === position
        ) {
          dispatch(play());
        } else {
          dispatch(getMediaPlaylist(contextName, contextId, position));
        }

        break;
      }
    }
  };
}

export function updateContextPosition(nextPosition) {
  return {
    type: acts.UPDATE_CONTEXT_POSITION,
    payload: nextPosition,
  };
}

export function updatePlayerFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_PLAYER_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updatePlayerRecents(id) {
  return {
    type: acts.UPDATE_PLAYER_RECENTS,
    payload: { id },
  };
}

// =============================
// Imports
// =============================

// Constants
import {
  INITIALIZE_APP,
  SET_PRIVACY_COOKIE,
  SET_SERVER_CONTEXT,
} from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  ready: false,
  hydrated: false,
  serverContext: {
    locationProtocol: null,
    xHost: null,
    ssrRequestHeaders: null,
  },
  privacyCookies: {
    mandatory: false,
    analytics: false,
    media: false,
  },
};

// =============================
// Reducer
// =============================

export default function core(state = initialState, action) {
  switch (action.type) {
    case SET_SERVER_CONTEXT:
      return {
        ...state,
        serverContext: action.payload,
      };

    case SET_PRIVACY_COOKIE:
      return {
        ...state,
        privacyCookies: {
          ...state.privacyCookies,
          [action.payload.name]: action.payload.value,
        },
      };

    case INITIALIZE_APP:
      return {
        ...state,
        ready: true,
      };

    default:
      return state;
  }
}

// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import _get from 'lodash/get';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import determineError from '../../helpers/errors';
import { getApiUrl, getXPreferredLanguage } from '../../helpers/misc';

// =============================
// Actions
// =============================

const NOTIFICATIONS_PER_PAGE = 10;

export function pushLatestNotification(notification) {
  return (dispatch, getState) => {
    const {
      pages: { notifications: { data, total, nbPages } },
    } = getState();

    if (data.find(notif => notif.id === notification.id)) {
      const updatedNotifications = data.map((notif) => {
        if (notif.id === notification.id) return notification;
        return notif;
      });

      // Update store
      dispatch({
        type: acts.SET_LATEST_USER_NOTIFICATIONS,
        payload: {
          data: updatedNotifications,
          total,
          nbPages,
        },
      });
    } else {
      // Remove last notifications to not break pagination when necessary
      const updatedNotifications = (data.length % NOTIFICATIONS_PER_PAGE) === 0
        ? data.slice(0, -1)
        : data;

      // Add new one
      updatedNotifications.unshift(notification);

      // Update total and nbPages for pagination system
      const nextTotal = total + 1;
      const nextNbPages = Math.ceil(nextTotal / NOTIFICATIONS_PER_PAGE);

      // Update store
      dispatch({
        type: acts.SET_LATEST_USER_NOTIFICATIONS,
        payload: {
          data: updatedNotifications,
          total: nextTotal,
          nbPages: nextNbPages,
        },
      });
    }
  };
}

export function getUserNotifications(page = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_NOTIFICATIONS_LOADING,
    });

    try {
      const response = await axios({
        method: 'get',
        url: getApiUrl(`public/users/notifications?page=${page}&max=${NOTIFICATIONS_PER_PAGE}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_USER_NOTIFICATIONS,
        payload: {
          data: response.data.data,
          page: response.data.page,
          nbPages: response.data.nb_pages,
          total: response.data.total,
        },
      });

      dispatch({
        type: acts.GET_USER_NOTIFICATIONS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_USER_NOTIFICATIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function checkUserNotifications() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.CHECK_USER_NOTIFICATIONS_LOADING,
    });

    try {
      await axios({
        method: 'post',
        url: getApiUrl('public/users/notifications/check'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-host': getState().core.serverContext.xHost,
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.CHECK_USER_NOTIFICATIONS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.CHECK_USER_NOTIFICATIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

// =============================
// Imports
// =============================

// External dependencies
import axios from 'axios';
import _get from 'lodash/get';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import determineError from '../../helpers/errors';
import { camelCaseKeysDeep, getApiUrl, getXPreferredLanguage } from '../../helpers/misc';
import { cancelableRequest, cancelRequest } from '../helpers/axios';

// =============================
// Actions
// =============================

export function getRecentlyPlayedTracks() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_RECENT_PLAYS_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_RECENT_PLAYS, {
        method: 'get',
        url: getApiUrl('public/users/recents'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_RECENT_PLAYS,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_RECENT_PLAYS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_RECENT_PLAYS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function updateRecentPlaysFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_RECENT_PLAYS_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function resetRecentlyPlayedTracks() {
  cancelRequest(rqs.GET_RECENT_PLAYS);

  return {
    type: acts.RESET_RECENT_PLAYS,
  };
}

export function addRecentlyPlayedTrack(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.ADD_TO_RECENT_PLAYS_LOADING,
    });

    try {
      await axios.post(
        getApiUrl(`public/users/recents/${id}`),
        {},
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
        },
      );

      dispatch({
        type: acts.ADD_TO_RECENT_PLAYS_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: acts.ADD_TO_RECENT_PLAYS_FAILURE,
        payload: {
          id,
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

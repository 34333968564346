// =============================
// Imports
// =============================

// External Dependencies
import _mapValues from 'lodash/mapValues';
import _mapKeys from 'lodash/mapKeys';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import _isObject from 'lodash/isObject';
import _compact from 'lodash/compact';
import _trim from 'lodash/trim';
import _get from 'lodash/get';

// Config
import { i18n } from '../config/i18n';
import locales from '../config/locales';

// =============================
// Helpers
// =============================

export const isDev = () => process.env.APP_ENV === 'local';
export const isPreprod = () => process.env.APP_ENV === 'preprod';

export const getApiUrl = suffix => (suffix ? `${process.env.API_URL}/${suffix}` : process.env.API_URL);

export const getXPreferredLanguage = () => i18n.language || locales.defaultLanguage;

export const getCookieConfig = (
  expiryDays,
  locationProtocol,
  fromServer = false,
) => {
  const opts = {};

  if (locationProtocol === 'https:') opts.secure = true;

  if (expiryDays && !fromServer) opts.expires = expiryDays;
  if (expiryDays && fromServer) {
    opts.expires = new Date(Date.now() + (expiryDays * 24 * 60 * 60 * 1000));
  }

  return opts;
};

export const getWebsiteTitle = (config, prefix) => _compact([prefix, _get(config, 'websiteTitle')]).join(' - ');

export const changeKeysDeep = cb => (obj) => {
  if (Array.isArray(obj)) return obj.map(changeKeysDeep(cb));

  if (_isObject(obj)) {
    return _mapValues(
      _mapKeys(obj, (_v, k) => cb(k)),
      changeKeysDeep(cb),
    );
  }

  return obj;
};

export const camelCaseKeysDeep = changeKeysDeep(_camelCase);
export const snakeCaseKeysDeep = changeKeysDeep(_snakeCase);

// Same as Promise.all, but wait till all promises fullfilled even when rejected
export const promiseAllAndWaitTillFulfilled = async promises => new Promise((resolve, reject) => {
  const results = Array.from({ length: promises.length });

  const resolveWhenComplete = () => {
    if (!results.every(e => e)) return;
    const error = (results.find(e => e[1]) || [])[1];
    if (error) reject(error);
    resolve(results.map(e => e[0]));
  };

  promises.map((p, i) => p
    .then((value) => {
      results[i] = [value, undefined];
    })
    .catch((err) => {
      results[i] = [undefined, err];
    })
    .finally(resolveWhenComplete),
  );
});

// Transform string to boolean
export const strToBool = initVal => ({
  isBoolStr: initVal === 'false' || initVal === 'true',
  value: initVal === 'true',
});

// Encode string and remove percent char
// This prevent React Link of throwing URI malformed error as % is invalid in RFC 3986
export const encodeAndRemovePercent = value => encodeURIComponent(_trim(value?.replace('%', '')));

// sleep promise
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// =============================
// Imports
// =============================

// External Dependencies
import axios, { isCancel } from 'axios';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';
import * as miscConfig from '../../config/misc';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import determineError, { MewoError } from '../../helpers/errors';
import { camelCaseKeysDeep, getApiUrl, getXPreferredLanguage, sleep } from '../../helpers/misc';
import { cancelableRequest, cancelRequest } from '../helpers/axios';

// =============================
// User Playlists List Actions
// =============================

export function getUserPlaylistsList() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_PLAYLISTS_LIST_LOADING,
    });

    try {
      const response = await axios.get(getApiUrl('public/users/playlists'), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_USER_PLAYLISTS_LIST,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_USER_PLAYLISTS_LIST_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_USER_PLAYLISTS_LIST_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

// =============================
// User Playlists Actions
// =============================

export function getUserPlaylists(nextQuery = '', refresh = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_PLAYLISTS_LOADING,
    });

    const { isFetchedOnce, page, query } = getState().userplaylists.playlists;
    const nextPage = isFetchedOnce && nextQuery === query && !refresh ? page + 1 : 0;

    try {
      const response = await cancelableRequest(rqs.GET_USER_PLAYLISTS, {
        method: 'post',
        url: getApiUrl('public/search/user/playlists'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: {
          query: nextQuery,
          page: nextPage,
          max: miscConfig.COVER_ITEMS_PER_PAGE,
        },
      });

      const responseData = camelCaseKeysDeep(response.data);

      dispatch({
        type: acts.SET_USER_PLAYLISTS,
        payload: {
          query: nextQuery,
          data: responseData.hits,
          total: responseData.total,
          page: nextPage,
          nbPages: responseData.nbPages,
        },
      });

      dispatch({
        type: acts.GET_USER_PLAYLISTS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let errorMsg;

        switch (true) {
          case err.response
            && err.response.status === 400
            && err.response.data.message.includes('must be less than or equal to 500 characters long'):
            errorMsg = i18n.t('errors:search.query_too_long', { max: 500 });
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        dispatch({
          type: acts.GET_USER_PLAYLISTS_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}

export function resetUserPlaylists() {
  cancelRequest(rqs.GET_USER_PLAYLISTS);

  return {
    type: acts.RESET_USER_PLAYLISTS,
  };
}

// =============================
// User Playlist Actions
// =============================

export function getUserPlaylist(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_PLAYLIST_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_USER_PLAYLIST, {
        method: 'get',
        url: getApiUrl(`public/users/playlists/${id}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_USER_PLAYLIST,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_USER_PLAYLIST_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_USER_PLAYLIST_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function createUserPlaylist() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.CREATE_USER_PLAYLIST_LOADING,
    });

    try {
      const nbUserPlaylists = _get(getState(), 'userplaylists.list.data', []).length || 0;

      const response = await axios.post(
        getApiUrl('public/users/playlists'),
        { name: `${i18n.t('common:playlist.new_playlist')} ${nbUserPlaylists + 1}` },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
        },
      );

      dispatch({
        type: acts.CREATE_USER_PLAYLIST_SUCCESS,
        payload: {
          id: response.data.id,
        },
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response && err.response.status === 406:
          message = i18n.t('errors:user_playlists.too_many_playlists');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.CREATE_USER_PLAYLIST_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function duplicateUserPlaylist(originalId, nextName) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.DUPLICATE_USER_PLAYLIST_LOADING,
    });

    try {
      const { data } = await axios.post(
        getApiUrl(`public/users/playlists/${originalId}/duplicate`),
        { name: nextName },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
        },
      );

      dispatch({
        type: acts.DUPLICATE_USER_PLAYLIST_SUCCESS,
        payload: {
          id: data.id,
          message: i18n.t('common:user_playlist.duplicate_success'),
        },
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response && err.response.status === 406:
          message = i18n.t('errors:user_playlists.too_many_playlists');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          message = i18n.t('errors:user_playlists.not_found');
          break;
        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.DUPLICATE_USER_PLAYLIST_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function updateUserPlaylist(id, data) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.MODIFY_USER_PLAYLIST_LOADING,
    });

    try {
      const response = await axios.put(getApiUrl(`public/users/playlists/${id}`), data, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      // NOTE: Only update name & description as it is the only updatable value
      dispatch({
        type: acts.SET_USER_PLAYLIST,
        payload: {
          ...getState().userplaylists.playlist.data,
          name: response.data.name,
          description: response.data.description,
        },
      });

      dispatch({
        type: acts.MODIFY_USER_PLAYLIST_SUCCESS,
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          message = i18n.t('errors:user_playlists.not_found');
          break;
        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.MODIFY_USER_PLAYLIST_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function removeUserPlaylists(ids, refreshUserPlaylists = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REMOVE_USER_PLAYLIST_LOADING,
    });

    try {
      await axios.delete(getApiUrl('public/users/playlists'), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: { playlistIds: ids },
      });

      dispatch({
        type: acts.REMOVE_USER_PLAYLIST_SUCCESS,
        payload: {
          redirect: !refreshUserPlaylists,
        },
      });

      // NOTE: Use this when on user playlists page to refresh the list
      if (refreshUserPlaylists) {
        // Wait for elastic search refresh
        await sleep(1500);

        const { query } = getState().userplaylists.playlists;
        dispatch(getUserPlaylists(query, true));
      }
    } catch (err) {
      dispatch({
        type: acts.REMOVE_USER_PLAYLIST_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

// =============================
// User Playlist Tracks Actions
// =============================

export function getUserPlaylistTracks(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_PLAYLIST_TRACKS_LOADING,
    });

    try {
      // NOTE: If a user changes playlist, the offset will be reset to 0
      const prevId = _get(getState(), 'userplaylists.playlist.data.id');
      const offset = prevId === id ? _get(getState(), 'userplaylists.playlistTracks.data', []).length : 0;

      const response = await cancelableRequest(rqs.GET_USER_PLAYLIST_TRACKS, {
        method: 'get',
        url: getApiUrl(
          `public/users/playlists/${id}/tracks?offset=${offset}&limit=${miscConfig.MUSIC_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_USER_PLAYLIST_TRACKS,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_USER_PLAYLIST_TRACKS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_USER_PLAYLIST_TRACKS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

// =============================
// User Playlist Global Actions
// =============================

export function resetUserPlaylistAndRelatedData() {
  cancelRequest(rqs.GET_USER_PLAYLIST);
  cancelRequest(rqs.GET_USER_PLAYLIST_TRACKS);

  return {
    type: acts.RESET_USER_PLAYLIST_AND_RELATED_DATA,
  };
}

export function updateUserPlaylistFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_USER_PLAYLIST_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateUserPlaylistRecents(id) {
  return {
    type: acts.UPDATE_USER_PLAYLIST_RECENTS,
    payload: { id },
  };
}

// =============================
// Tracks Actions
// =============================

export function addItemsToPlaylist(type, itemId, playlistId = null, playlistTitle = '') {
  return async (dispatch, getState) => {
    let targetPlaylistId = playlistId;

    const createPlaylistEndpoint = getApiUrl('public/users/playlists');
    let addItemEndpoint;

    try {
      if (!targetPlaylistId) {
        const nbUserPlaylists = _get(getState(), 'userplaylists.list.data', []).length || 0;

        const response = await axios({
          method: 'post',
          url: createPlaylistEndpoint,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
          data: {
            name: playlistTitle || `${i18n.t('common:playlist.new_playlist')} ${nbUserPlaylists + 1}`,
          },
        });

        targetPlaylistId = response.data.id;
      }

      dispatch({
        type: acts.ADD_TRACK_TO_PLAYLIST_LOADING,
        payload: {
          id: targetPlaylistId,
        },
      });

      switch (type) {
        case 'album':
        case 'playlist':
          addItemEndpoint = getApiUrl(`public/users/playlists/${targetPlaylistId}/add/${type}/${itemId}`);
          break;

        case 'track':
        default:
          addItemEndpoint = getApiUrl(`public/users/playlists/${targetPlaylistId}/tracks/${itemId}`);
          break;
      }

      const response = await axios({
        method: 'put',
        url: addItemEndpoint,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: {},
      });

      const currentPlaylistId = _get(getState(), 'userplaylists.playlist.data.id');

      // EG: Adding a track version to playlist when on that specific playlist page
      if (type === 'track' && targetPlaylistId === currentPlaylistId) {
        const prevData = getState().userplaylists.playlistTracks.data;
        const nextData = [
          response.data,
          ...prevData,
        ];

        dispatch({
          type: acts.REPLACE_USER_PLAYLIST_TRACKS_DATA,
          payload: nextData,
        });
      }

      return dispatch({
        type: acts.ADD_TRACK_TO_PLAYLIST_SUCCESS,
        payload: {
          id: targetPlaylistId,
        },
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 406
          && err.config.url === createPlaylistEndpoint:
          message = i18n.t('errors:user_playlists.too_many_playlists');
          break;

        case err.response
          && err.response.status === 406
          && err.config.url !== createPlaylistEndpoint:
          message = i18n.t('errors:user_playlists.too_many_tracks');
          break;

        default:
          message = determineError(err);
          break;
      }

      return dispatch({
        type: acts.ADD_TRACK_TO_PLAYLIST_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
          id: targetPlaylistId,
        },
      });
    }
  };
}

export function reorderPlaylistTrack(playlistId, trackId, index, destination) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REORDER_TRACK_IN_PLAYLIST_LOADING,
    });

    // Re-order on front-end first
    dispatch({
      type: acts.REORDER_USER_PLAYLIST_TRACK,
      payload: {
        playlistId,
        trackId,
        index,
        destination,
      },
    });

    try {
      await axios.put(
        getApiUrl(`public/users/playlists/${playlistId}/tracks/${trackId}`),
        { position: destination },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
            ...getState().core.serverContext.ssrRequestHeaders,
          },
        },
      );

      dispatch({
        type: acts.REORDER_TRACK_IN_PLAYLIST_SUCCESS,
        payload: {
          trackId,
          playlistId,
          nextPlayerPosition: destination + 1,
        },
      });
    } catch (err) {
      let message;
      switch (true) {
        case err.response && err.response.status === 406:
          message = i18n.t('errors:user_playlists.too_many_tracks');
          break;
        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.REORDER_TRACK_IN_PLAYLIST_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function removeTrackFromPlaylist(playlistId, trackId) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REMOVE_TRACK_FROM_PLAYLIST_LOADING,
      payload: {
        id: playlistId,
      },
    });

    try {
      await axios.delete(getApiUrl(`public/users/playlists/${playlistId}/tracks/${trackId}`), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      const currentPlaylistId = _get(getState(), 'userplaylists.playlist.data.id');

      // EG: Removing a track from playlist when on that specific playlist page
      if (playlistId === currentPlaylistId) {
        const prevData = getState().userplaylists.playlistTracks.data;

        dispatch({
          type: acts.REPLACE_USER_PLAYLIST_TRACKS_DATA,
          payload: prevData.filter(d => d.id !== trackId),
        });
      }

      return dispatch({
        type: acts.REMOVE_TRACK_FROM_PLAYLIST_SUCCESS,
        payload: {
          id: playlistId,
        },
      });
    } catch (err) {
      return dispatch({
        type: acts.REMOVE_TRACK_FROM_PLAYLIST_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
          id: playlistId,
        },
      });
    }
  };
}

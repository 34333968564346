// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import determineError, { MewoError } from '../../helpers/errors';
import {
  camelCaseKeysDeep,
  getApiUrl,
  getXPreferredLanguage,
  snakeCaseKeysDeep,
} from '../../helpers/misc';
import { cancelableRequest, cancelRequest } from '../helpers/axios';

// =============================
// Actions
// =============================

export function getConfig() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CONFIG_LOADING,
    });

    try {
      const response = await axios.get(getApiUrl('public/config'), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      const configData = camelCaseKeysDeep(response.data);

      // Hide mailchimp sensible data
      configData.integrations.mailchimp = !!_get(configData, 'integrations.mailchimp.apiKey')
        && !!_get(configData, 'integrations.mailchimp.audienceId');

      dispatch({
        type: acts.SET_CONFIG,
        payload: configData,
      });

      dispatch({
        type: acts.GET_CONFIG_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_CONFIG_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });

      throw new MewoError({ error: err });
    }
  };
}

export function getConfigLegal() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CONFIG_LEGAL_LOADING,
    });

    try {
      const response = await axios.get(getApiUrl('public/config/legal'), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_CONFIG_LEGAL,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_CONFIG_LEGAL_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_CONFIG_LEGAL_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });

      throw new MewoError({ error: err });
    }
  };
}

export function getConfigMenus() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CONFIG_MENUS_LOADING,
    });

    try {
      const response = await axios.get(getApiUrl('public/config/menus'), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_CONFIG_MENUS,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_CONFIG_MENUS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_CONFIG_MENUS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });

      throw new MewoError({ error: err });
    }
  };
}

export function getConfigHome(draftToken = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CONFIG_HOME_LOADING,
    });

    let route = getApiUrl('public/home');
    if (draftToken) route += `?draftToken=${draftToken}`;

    try {
      const response = await axios.get(route, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_CONFIG_HOME,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_CONFIG_HOME_SUCCESS,
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'not_found':
          message = i18n.t('errors:config_page.not_found');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.GET_CONFIG_HOME_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });

      throw new MewoError({ message, error: err });
    }
  };
}

export function getConfigPage(slug, draftToken = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CONFIG_PAGE_LOADING,
    });

    let route = getApiUrl(`public/pages/${slug}`);
    if (draftToken) route += `?draftToken=${draftToken}`;

    try {
      const response = await cancelableRequest(rqs.GET_CONFIG_PAGE, {
        method: 'get',
        url: route,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
      });

      dispatch({
        type: acts.SET_CONFIG_PAGE,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_CONFIG_PAGE_SUCCESS,
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'not_found':
          message = i18n.t('errors:config_page.not_found');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.GET_CONFIG_PAGE_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });

      throw new MewoError({ message, error: err });
    }
  };
}

export function resetConfigPage() {
  cancelRequest(rqs.GET_CONFIG_PAGE);

  return {
    type: acts.RESET_CONFIG_PAGE,
  };
}

export function sendContactEmail(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.SEND_CONTACT_EMAIL_LOADING,
    });

    try {
      await axios({
        method: 'post',
        url: getApiUrl('public/contact'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          ...getState().core.serverContext.ssrRequestHeaders,
        },
        data: snakeCaseKeysDeep(data),
      });

      dispatch({
        type: acts.SEND_CONTACT_EMAIL_SUCCESS,
        payload: {
          message: i18n.t('pages:custom_page.modules.contact_form.send_contact_email_success'),
        },
      });
    } catch (err) {
      dispatch({
        type: acts.SEND_CONTACT_EMAIL_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

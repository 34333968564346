// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import ModalAddToPlaylist from '../../../layouts/modals/addtoplaylist';

import { setAddToPlaylistOpen } from '../../../../store/actions/ModalsActions';
import { addItemsToPlaylist, removeTrackFromPlaylist } from '../../../../store/actions/UserPlaylistsActions';

// =============================
// Component
// =============================

function mapStateToProps({ core, user, modals, userplaylists }) {
  return {
    xHost: core.serverContext.xHost,
    ssrRequestHeaders: core.serverContext.ssrRequestHeaders,
    userToken: user.token,
    opened: modals.addToPlaylist.isOpen,
    entity: modals.addToPlaylist.data,
    type: modals.addToPlaylist.type,
    userPlaylists: userplaylists.list.data,
    isHandlingTracks: userplaylists.playlistTracks.isHandlingTracks,
  };
}

export default connect(mapStateToProps, {
  closeAddToPlaylistModal: () => setAddToPlaylistOpen(false),
  addItemsToPlaylist,
  removeTrackFromPlaylist,
})(ModalAddToPlaylist);
